export default [
  {
    header: 'Home',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Incubator Manager',
    icon: 'SlidersIcon',
    route: 'manage-incubator',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Sessions',
    icon: 'VideoIcon',
    route: 'sessions-manager',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Requests',
    icon: 'SendIcon',
    route: 'request-details',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Partner Services',
    icon: 'PenToolIcon',
    route: 'partner-services-details',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Library',
    icon: 'CpuIcon',
    route: 'library-module-details',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Forms',
    icon: 'FileTextIcon',
    route: 'forms-module-details',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Invitations',
    route: 'mentor-invitations',
    icon: 'UserPlusIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Sessions',
    icon: 'VideoIcon',
    route: 'partner-sessions',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Requests',
    icon: 'SendIcon',
    route: 'request-details-mentor',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Library',
    icon: 'CpuIcon',
    route: 'mentor-library-module-details',
    resource: 'Mentor',
    action: 'read',
  },
  // {
  //   title: 'My Organizations',
  //   route: 'partner-organizations',
  //   icon: 'BriefcaseIcon',
  //   resource: 'Mentor',
  //   action: 'read',
  // },
  // {
  //   title: 'Forms',
  //   route: 'mentor-forms-module-details',
  //   icon: 'FileTextIcon',
  //   resource: 'Mentor',
  //   action: 'read',
  // },
  {
    title: 'Startup Manager',
    route: 'startup-manager',
    icon: 'PieChartIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Mentors',
    icon: 'AwardIcon',
    route: 'startup-mentors',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Sessions',
    icon: 'VideoIcon',
    route: 'startup-sessions',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Requests',
    route: 'startup-requests',
    icon: 'SendIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Partner Services',
    icon: 'PenToolIcon',
    route: 'startup-partner-services',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Library',
    icon: 'CpuIcon',
    route: 'startup-library-module-details',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Job Listing',
    icon: 'BriefcaseIcon',
    route: 'job-listing',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Incubator Manager',
    route: 'incubator-manager',
    icon: 'CompassIcon',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Forms',
    route: 'incubator-forms',
    icon: 'FileTextIcon',
    resource: `${JSON.parse(localStorage.getItem('userData')).associatedOrgDetails.length > 0 ? 'User' : 'None'}`,
    action: 'read',
  },
]
