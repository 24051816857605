export default [
  {
    header: 'Manage Ecosystem',
  },
  {
    title: 'Incubator Manager',
    route: 'mentor-incubators',
    icon: 'CompassIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Startups',
    route: 'mentor-startups',
    icon: 'PieChartIcon',
    resource: 'Mentor',
    action: 'read',
  },
  // {
  //   title: 'Students',
  //   route: 'mentor-students',
  //   icon: 'UserIcon',
  //   resource: 'Mentor',
  //   action: 'read',
  // },
  // {
  //   title: 'Mentor Portal',
  //   icon: 'AwardIcon',
  //   children: [
  //     {
  //       title: 'Invitations',
  //       route: 'mentor-invitations',
  //       icon: 'UserPlusIcon',
  //       resource: 'Mentor',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Program',
  //       route: 'mentor-programs',
  //       icon: 'GridIcon',
  //       resource: 'Mentor',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Manage Incubator',
  //       route: 'mentor-incubators',
  //       icon: 'CompassIcon',
  //       resource: 'Mentor',
  //       action: 'read',
  //     },
  //   ],
  // },
]
